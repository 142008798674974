
<template>
    <section>        
        <b-loading :active.sync="isLoading"></b-loading>


        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(setNomePaciente)">
                            <div class="columns">
                                <div class="column is-12 is-flex">
                                    <b-autocomplete field="nome"
                                                    class="is-flex-grow-1"
                                                    v-model="buscaPaciente"
                                                    :placeholder="$t('ATENDIMENTO.PROCURARPACIENTE')"
                                                    :data="listaPacienteAutocomplete"
                                                    :loading="isFetching"
                                                    @keydown.native.enter.prevent="carregaPaciente()"
                                                    @typing="procurarPacienteAutocomplete"
                                                    @select="option => {selected = option; carregaPaciente()}"
                                                    autocomplete="no">
                                        <template slot-scope="props">
                                            <div class="media">
                                                <div class="media-left">
                                                    <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                                </div>
                                                <div class="media-content">
                                                    {{ props.option.nome }}
                                                    <br>
                                                    <small>
                                                        <b>{{ props.option.dataDeNascimentoIdade }}</b>
                                                        <span v-if="props.option.inscricao">{{$t('ATENDIMENTO.INSCRICAO')}}<b>{{ props.option.inscricao }}</b></span>
                                                    </small>
                                                </div>
                                                <div class="media-right">
                                                    <div v-if="props.option.pacienteEspecie && props.option.pacienteRaca">
                                                        <b>{{$t('ATENDIMENTO.ESPECIE')}}: </b> {{ props.option.pacienteEspecie }} 
                                                        <b>{{$t('ATENDIMENTO.RACA')}}: </b> {{ props.option.pacienteRaca }} 
                                                    </div>
                                                    <div  v-if="props.option.nomeResponsavel">
                                                        <b>{{$t('ATENDIMENTO.RESPONSAVEL')}}</b> {{ props.option.nomeResponsavel }} 
                                                    </div>                                    
                                                </div>
                                            </div>
                                        </template>
                                    </b-autocomplete>
                                    <slot>
                                        <b-button
                                            icon-left="plus-box"
                                            :title="$t('INSPECAO.INCLUIRPACIENTE')"
                                            native-type="submit"
                                            @click="setNomePaciente">
                                        </b-button>
                                    </slot>
                                </div>
                            </div>

                        
                            <div class="columns">
                                <div class="column is-6">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PACIENTECADASTRO.NOME')"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                    :message="errors">
                                                    <b-input :disabled="isReadOnly" maxlength="150" type="text" v-model="paciente.nome" autocomplete="no"></b-input>                                        </b-field>
                                    </ValidationProvider>
                                </div>

                                <div class="column is-2">                                    
                                    <ValidationProvider :rules="`${config.language == 'es' ? '' : 'cpf:14'}|required`" name="cpf" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PACIENTECADASTRO.CPF')"
                                                    ref="cpf"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                    :message="errors">
                                            <b-input type="text"
                                                    :disabled="isReadOnly"
                                                    v-model="paciente.cpf"
                                                     v-if="config.language == 'es'" maxlength="20"
                                                     @keydown.native.enter.prevent="carregaPacientePorCPF()"
                                                     autocomplete="no"></b-input>
                                            <b-input type="text"
                                                    :disabled="isReadOnly"
                                                     v-model="paciente.cpf"
                                                     v-else v-mask="'###.###.###-##'" maxlength="14"
                                                     @keydown.native.enter.prevent="carregaPacientePorCPF()"
                                                     autocomplete="no"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>

                                <div class="column is-4">
                                    <ValidationProvider rules="email" name="e-mail" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PACIENTECADASTRO.EMAIL')"
                                                    ref="email"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                    :message="errors">
                                            <b-input :disabled="isReadOnly" maxlength="50" type="email" v-model="paciente.email" @blur="verificarEmail" :readonly="paciente.possuiUsuario" autocomplete="no"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-2" style="width: auto;">
                                    <ValidationProvider rules="required" name="sexo" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PACIENTECADASTRO.SEXO')"
                                                    :message="errors"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select :placeholder="$t('PACIENTECADASTRO.SELECIONESEXO')" :disabled="isReadOnly" v-model="paciente.sexo" autocomplete="no">
                                                <option value="F">{{$t('PACIENTECADASTRO.FEM')}}</option>
                                                <option value="M">{{$t('PACIENTECADASTRO.MASC')}}</option>
                                                <option v-show="config.laboratorioVeterinario || config.permitirSexoIndeterminado" value="I">{{$t('PACIENTECADASTRO.INDT')}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-2">
                                    <ValidationProvider rules="required" name="data de nascimento" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PACIENTECADASTRO.NASCIMENTO')"
                                                    :message="errors"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-datepicker v-model="paciente.dataDeNascimento" :disabled="isReadOnly" trap-focus editable :max-date="new Date()" 
                                                :min-date="new Date(new Date().getFullYear() - 200, new Date().getMonth(), new Date().getDate())"></b-datepicker>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-2 texto-labels">
                                    <div class="field">
                                        <label class="label">{{$t('PACIENTECADASTRO.PESO')}}</label>
                                        <div class="control">
                                            <input class="input" type="number" step="0.01" :value="isReadOnly ? reagendamento.pacientePeso : paciente.peso" :disabled="isReadOnly" autocomplete="no">
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-3">
                                    <div class="field">
                                        <label class="label">{{$t('PACIENTECADASTRO.INSCRICAO')}}</label>
                                        <div class="control">
                                            <b-input type="inscricao" :value="isReadOnly ? reagendamento.pacienteInscricao : paciente.inscricao" :disabled="isReadOnly" autocomplete="no"></b-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-1">
                                    <div class="field">
                                        <label class="label">{{$t('PACIENTECADASTRO.DV')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" :value="isReadOnly ? reagendamento.pacienteInscricaoDV : paciente.inscricaoDV" v-mask="'#'" :disabled="isReadOnly" autocomplete="no">
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-2">
                                    <b-field :label="$t('PACIENTECADASTRO.CELULAR')">
                                        <b-input expanded
                                                    v-model="paciente.celular" 
                                                    :disabled="isReadOnly"
                                                    v-mask="['(##) ####-####', '(##) #####-####']"
                                                    :icon-right-clickable="podeEnviarSms"
                                                    @icon-right-click="abrirModalSms"
                                                    :icon-right="iconeEnviarSms"
                                                    autocomplete="no"></b-input>
                                    </b-field>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>

                    <div class="columns is-12">
                        <div class="column is-4">
                            <searchIdName v-show="(!examesLaboratorial)" table="Convenio" :label="$t('SISTEMA.CONVENIO')"  :id.sync="convenioIdAgendar" @item="escolheuConvenio"  autocomplete="no" >
                            </searchIdName>
                        </div>
                        <div class="column is-4">
                            <searchIdName  v-show="(this.agendas && this.agendas.length >0)"  table="FuncionarioComCrm" :label="$t('AGENDA.EXECUTANTE')" :id.sync="executanteIdAgendar" @item="escolheuExecutante" autocomplete="no">
                            </searchIdName>                                                       
                        </div>                        


                        <div class="column is-2">
                                <label class="label" style="visibility: hidden;">Label Filtro</label>
                                <b-tooltip :label="$t('AGENDA.MENSAGEMEXAMELABORATORIAL')" multilined>
                                    <b-switch v-model="examesLaboratorial" native-value="false">                                        
                                         {{$t('AGENDA.EXAMELABORATORIAL')}}
                                    </b-switch>                        
                                </b-tooltip>
                        </div>
                         
                    </div>
                        <div v-show="(!examesLaboratorial)" class="columns">
                                                          
                            <div class="column is-12">
                                <nav class="panel">
                                    <p class="panel-heading">
                                        {{$t('AGENDA.AGENDAREXAMES')}}
                                        <button v-show="listaExames && listaExames.length>0"  type="button" class="delete is-pulled-right" aria-label="delete" @click="listaExames=[]; procurarExameTexto=null;"></button>
                                    </p>
                                    <div class="panel-block" v-show="examesSelecionados && examesSelecionados.length>0">
                                        <b-taginput
                                            v-model="examesSelecionados"                                            
                                            autocomplete
                                            :allow-new="false"                                                                                                                                                      
                                            field="nome"
                                            icon="label" >                                                          
                                        </b-taginput>                                        
                                    </div>
                                    <div class="panel-block">
                                        <b-input v-model="procurarExameTexto"
                                                 icon="magnify"
                                                 @input="inputListaExames"
                                                 @keydown.native.esc="listaExames=[]; procurarExameTexto=null;"
                                                 @keydown.native.down="incluindoExameIndex++"
                                                 @keydown.native.up="incluindoExameIndex--"
                                                 @keydown.native.enter.prevent="incluirExame(listaExames[incluindoExameIndex])"
                                                 placeholder="Pesquisar Exames"
                                                 ref="procurarExameTextoInput"></b-input>

                                        <b-dropdown aria-role="list"
                                                    position="is-bottom-left"
                                                    v-model="procurarExamePor"
                                                    @input="$refs.procurarExameTextoInput.focus()">

                                            <button class="button" slot="trigger" slot-scope="{ active }">
                                                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                            </button>
                                            <b-dropdown-item aria-role="menu-item" custom>
                                                <small>{{$t('GUIAATENDIMENTO.PROCURARPOR')}}</small>
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="apelido">
                                                {{$t('GUIAATENDIMENTO.APELIDO')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="Nome">
                                                {{$t('GUIAATENDIMENTO.NOME')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="Codigo Externo">
                                                {{$t('GUIAATENDIMENTO.CODIGOEXTERNO')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="Id">
                                                {{$t('GUIAATENDIMENTO.IDENTIFICACAO')}}
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>                                    
                                    <template v-for="(exame, index) in listaExames" >
                                        <template v-if="index == 0 || exame.quantidadeDeAmostras <= 1 || listaExames[index - 1].id != exame.id">
                                            <span class="panel-block columns is-multiline" style="border: 1px solid red; background-image: none !important; margin: 2px 0px;" 
                                                v-if="exame.bloqueado" :key="index">
                                                <div class="column is-2">
                                                    <h4 class="is-size-5 has-text-weight-bold">
                                                        {{ exame.apelido }}
                                                    </h4>
                                                </div>
                                                <div class="column is-6">
                                                    <p>{{ exame.nome }}</p>
                                                    <small class="is-italic" v-if="exame.quantidadeDeAmostras < 2 && 
                                                        exame.quantidadeDeAmostras != listaExames.filter(exameLista => exameLista.id == exame.id).length">
                                                        {{ exame.materialNome }} / {{ exame.conservanteNome }}
                                                    </small>
                                                    <small v-else>
                                                        <span v-for="(item, i) in listaExames.filter(exameLista => exameLista.id == exame.id)" :key="i">
                                                            {{ item.materialNome }} / {{ item.conservanteNome }}
                                                        </span>
                                                    </small>
                                                    <p v-if="exame.bloqueado">
                                                        <br />
                                                        <span class="tag">{{$t('GUIAATENDIMENTO.BLOQUEADO')}}{{exame.motivoBloqueio}}</span>
                                                    </p>
                                                </div>
                                                <div class="column is-3">
                                                    <p v-if="isExterno" class="is-italic">{{ exame.departamentoNome }}</p>
                                                    <p v-else class="is-italic">{{ exame.setorNome }}</p>
                                                </div>
                                                <div class="column is-1">
                                                    <span class="tag is-pulled-right" @click="mostraDetalhes(exame.id, $event)">
                                                        <span class="is-size-7">{{exame.codigo}}</span>
                                                        <b-icon icon="format-list-bulleted"></b-icon>
                                                    </span>
                                                </div>
                                                <div class="column is-12" v-show="mostraDetalhesExameId == exame.id" :key="index">
                                                    <pre style="white-space: pre-wrap;       /* Since CSS 2.1 */
                                                        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                                                        white-space: -pre-wrap;      /* Opera 4-6 */
                                                        white-space: -o-pre-wrap;    /* Opera 7 */
                                                        word-wrap: break-word;  ">Jejum: {{exame.minimoJejum}}
        Prazo em Dias: {{ exame.prazoDeEntrega }} dias, {{ exame.prazoDeEntregaHoras }} horas
        Informações ao Paciente: {{exame.informacoesPaciente}}
        Informações ao Laboratório: {{exame.informacoesLaboratorio}}</pre>
                                                </div>
                                            </span>
                                            <a class="panel-block columns is-multiline" v-else :key="index" @click="incluirExame(exame)" v-bind:class="{ 'has-background-success': incluindoExameIndex == index }">
                                                <div class="column is-2">
                                                    <h4 class="is-size-5 has-text-weight-bold">
                                                        {{ exame.apelido }}
                                                    </h4>
                                                </div>
                                                <div class="column is-6">
                                                    <p>{{ exame.nome }}</p>
                                                    <small class="is-italic" v-if="exame.quantidadeDeAmostras < 2 && 
                                                        exame.quantidadeDeAmostras != listaExames.filter(exameLista => exameLista.id == exame.id).length">
                                                        {{ exame.materialNome }} / {{ exame.conservanteNome }}
                                                    </small>
                                                    <small v-else>
                                                        <span v-for="(item, i) in listaExames.filter(exameLista => exameLista.id == exame.id)" :key="i">
                                                            {{ item.materialNome }} / {{ item.conservanteNome }}
                                                        </span>
                                                    </small>
                                                </div>
                                                <div class="column is-3">
                                                    <p v-if="isExterno" class="is-italic">{{ exame.departamentoNome }}</p>
                                                    <p v-else class="is-italic">{{ exame.setorNome }}</p>
                                                </div>
                                                <div class="column is-1">                                                
                                                    <span class="is-size-7">{{exame.codigo}}</span>
                                                    <span class="tag is-pulled-right" @click="mostraDetalhes(exame.id, $event)">                                                        
                                                        <b-icon icon="format-list-bulleted"></b-icon>
                                                    </span>
                                                </div>
                                                <div class="column is-12" v-show="mostraDetalhesExameId == exame.id" :key="index">
                                                    <pre style="white-space: pre-wrap;       /* Since CSS 2.1 */
                                                        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                                                        white-space: -pre-wrap;      /* Opera 4-6 */
                                                        white-space: -o-pre-wrap;    /* Opera 7 */
                                                        word-wrap: break-word;  ">Jejum: {{exame.minimoJejum}}
        Prazo em Dias: {{ exame.prazoDeEntrega }} dias, {{ exame.prazoDeEntregaHoras }} horas
        Informações ao Paciente: {{exame.informacoesPaciente}}
        Informações ao Laboratório: {{exame.informacoesLaboratorio}}</pre>
                                                </div>
                                            </a>
                                        </template>
                                    </template>
                                </nav>
                            </div>
                 
                        </div>    
                        <div v-show="(!examesLaboratorial)" class="columns">                        
                            <div class="column is-8">
                                <label class="label">{{$t('PACIENTECADASTRO.OBSERVACOES')}}</label>
                                <div class="control">
                                    <textarea class="textarea" rows="3"  maxlength="500" v-model="observacoesInspecao" autocomplete="no"></textarea>
                                </div>                                
                            </div>  
                            <div class="column is-1 has-text-centered" v-if="(this.agendas && this.agendas.length >0) || this.permiteSalvar">
                                    <label class="label" style="visibility: hidden;">Label Filtro</label>
                                    <b-button type="is-success" @click="salvarAgendamentos()" icon-left="check-circle"> 
                                        {{ $t('CONFIG.SALVAR') }}
                                    </b-button>                                                   
                            </div>                        
                            <div class="column is-1 has-text-centered" v-if="(this.agendas && this.agendas.length >0 && this.eventosSalvar.length >0 )">
                                    <label class="label" style="visibility: hidden;">Label Filtro</label>
                                    <b-button type="is-danger" @click="cancelarAgendamentos()" icon-left="close-circle"> 
                                        {{$t('SISTEMA.CANCELAR')}}
                                    </b-button>                                                                                                           
                            </div>                                             
                        </div>                      

                    <div class="columns is-multiline is-mobile is-gapless">
                        <b-checkbox-button class="column is-2-desktop is-half-mobile" v-for="(tipo) in tiposAgendamentoAtivos" v-bind:key="tipo.id" 
                                v-model="filtrosAgendamentoId"
                                :disabled="tipoAgendamentoDisabled"
                                :native-value="tipo.id">
                            <b-icon icon="rectangle"  v-if="tipo.cor" :style="tipoAgendamentoStyle(tipo)"></b-icon>           
                            <span class="text-nowrap">{{tipo.nome}}</span>
                        </b-checkbox-button>
                    </div>
                </article>

                <div v-if="reagendamento" class="columns p-0"> 
                    <div  class="column is-12 p-0">
                        <b-notification type="is-info">
                            <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}} - {{$t('AGENDA.MENSAGEMREAGENDAMENTO')}}</h5>                            
                            <p>{{$t('AGENDA.MENSAGEMREAGENDAMENTOEVENTO', {'0': reagendamento.pacienteNome,
                                                                           '1':$moment(reagendamento.dataHora).format('DD/MM/YYYY  HH:mm'),
                                                                           '2':  reagendamento.guiaAgendaExamesNome.map(e => e).join(",") })}} 
                                <b-button :title="$t('AGENDA.MENSAGEMREAGENDAMENTOBOTAO')"
                                @click="cancelarReagendamento" icon-left="close-circle" size="is-small" type="is-danger" ></b-button>                            
                            </p>                       
                        </b-notification>                                            
                    </div>                                    
                </div>

                <div v-show="!isLoading" class="columns whitebox"> 
                    <div v-if="agendas.length>1" class="column is-12  has-text-centered"> 
                        <span>
                            <b-button  icon-left="chevron-triple-left" :title="$t('AGENDA.DIAANTERIORVAGOTODOS')" @click="proximoDiaVagoTodos(false);" ></b-button>                                                                            
                            <b-button  icon-left="chevron-left"  :title="$t('AGENDA.DIAANTERIORTODOS')" @click="proximoDiaTodos(-1);" ></b-button>                                                           
                            <b-button type="is-primary"  icon-left="calendar" title="Especificar data de todas as agendas" @click="confirmarAlteracaoDataAgendas()">                                    
                                Ir para Data
                            </b-button>                  
                            <b-button  icon-left="chevron-right"  :title="$t('AGENDA.PROXIMODIATODOS')"  @click="proximoDiaTodos(1);" ></b-button>                                                                                                                         
                            <b-button   icon-left="chevron-triple-right" :title="$t('AGENDA.PROXIMODIAVAGOTODOS')"  @click="proximoDiaVagoTodos(true);" ></b-button>                                    
                            
                        </span>

                    </div>                                        
                </div>

                <div v-show="!isLoading" class="columns whitebox"> 
                    <div style="border-right: 3px #ced8ce solid;" class="column is-fullwidth-mobile p-0" v-for="(agenda, index) in agendas" v-bind:key="index">
                        <b-collapse  aria-id="agendaDias">
                            <template slot="header">
                                <span>  {{$t('AGENDA.DIARIO')}}  </span>
                            </template>
                            <div class="columns is-multiline whitebox" v-show="!isLoading">                                                                               
                                <div class="column is-12 has-text-centered whitebox" > 
                                    <div>
                                        <b-icon icon="rectangle"  v-if="agenda.cor" :style="tipoAgendamentoStyle(agenda)"></b-icon>
                                        <span>{{ agenda.nome }}</span>
                                    </div>
                                        <b-button class="is-pulled-left " :title="$t('AGENDA.DIAANTERIORVAGO')"  icon-left="chevron-triple-left" @click="proximoDiaVago(-1,agenda);" ></b-button>                                                                            
                                        <b-button class="is-pulled-left"  :title="$t('AGENDA.DIAANTERIOR')" icon-left="chevron-left" @click="proximoDia(-1,agenda);" ></b-button>        

                                        <a href="#" class="has-text-grey" title="Alterar data desta agenda" @click="confirmarAlteracaoDataAgendas(agenda);">
                                            <b-icon icon="calendar" size="is-small"></b-icon>
                                        </a>
                                        <span class="is-size is-inline-block mt-2"><b>{{$moment(agenda.dataDiaFiltro).format('DD/MM/YYYY')}} - {{ getDayOfWeek(agenda.dataDiaFiltro)}}</b></span>                                     
                                        
                                        <b-button class="is-pulled-right " :title="$t('AGENDA.PROXIMODIAVAGO')"  icon-left="chevron-triple-right" @click="proximoDiaVago(1,agenda);" ></b-button>                                    
                                        <b-button class="is-pulled-right"  :title="$t('AGENDA.PROXIMODIA')" icon-left="chevron-right"  @click="proximoDia(1,agenda);" ></b-button> 
                                        <span v-if="(!examesLaboratorial)">
                                            <b-button v-if="isInRole('agenda-incluir-encaixe')"  class="is-pulled-right mr-4"  icon-left="arrow-collapse-vertical" @click="confirmarEncaixe(agenda)"  :title="$t('AGENDA.ENCAIXE')" ></b-button>                                                                                                                                                           
                                        </span>
                                        <span v-else>
                                            <b-button v-if="isInRole('agenda-incluir-encaixe')"  class="is-pulled-right mr-4"  icon-left="arrow-collapse-vertical"  :title="$t('AGENDA.ENCAIXE')" tag="router-link" :to="{ name: 'agendamento',params: {permiteAlterar:true, tipoAgendamentoId: agenda.tipoAgendamentoId, encaixe:true} }" ></b-button>                                                                                                                                                           
                                        </span>

                                        
                                </div>                                 
                                <inspecao-item v-if="agenda.eventosDia.length>0" :tiposAgendamentoAtivos="tiposAgendamentoAtivos" :usuario="usuario" :eventos="agenda.eventosDia" @incluirNovoHorarioDia="incluirNovoHorarioDia" 
                                     @loadDataDia="loadDataDia"  @verificarAgenda="verificarAgenda"  :exibirCheckBox="(agenda.possuiEquipamentoVinculado && agendas.length >0 )"></inspecao-item>                                  
                                <div v-else class="notification column is-12 is-warning">
                                    <h4>{{$t('AGENDA.ESCALASNAOCADASTRADAS')}}</h4>                                    
                                </div>                                                              
                            </div>
                        </b-collapse>
                    </div>
                </div>
            </div>            
        </div>
       
    </section>
</template>

<style lang="scss" scoped>
    
    .text-nowrap{
        text-overflow: ellipsis;
        white-space: nowrap;        
        overflow: hidden;                
    }
  
  .whitebox{
    height: auto !important;
    width: 100% !important;
  }

</style>

<script>
    import {  mapState,mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue';
    import moment from 'moment';    
    import inspecaoItem from '@/components/atendimento/inspecao-item.vue';
    import searchIdName from '@/components/searchIdName.vue';
    import modalAlterarDataAgendamento from '@/components/atendimento/modalAlterarDataAgendamento.vue'
    import modalAlterarDataEncaixe from '@/components/atendimento/modalAlterarDataEncaixe.vue'
    import modalAgendamentoConcluido from '@/components/atendimento/modalAgendamentoConcluido.vue'
    import debounce from 'lodash/debounce'        
    import modalEnviarSms from '@/components/modalEnviarSms.vue'
 
    export default {
        components: {
            titlebar,
            inspecaoItem,
            searchIdName,
            
        },

        computed: {
            isReadOnly() {
                return this.reagendamento !== null; 
            },
            ...mapState([                
                'config',
                'unidadeId',
                'componentes',
                'usuario'
            ]),  
            ...mapGetters([
                'isInRole'
            ]) ,                    
            titleStack() {
                return [
                    this.$t('AGENDA.ATENDIMENTO'),
                    this.$t('ATENDIMENTO.INSPECAO'),
                ]
            },
            podeEnviarSms() {
                return this.paciente.celular && this.paciente.celular.length >= 14
            },
            iconeEnviarSms() {
                return this.podeEnviarSms
                    ? "cellphone-message"
                    : null
            },
        },
        watch: {
            unidadeId: function(novaUnidade) {                 
                this.$route.query.unidadeId = novaUnidade;                
                this.$forceUpdate();
                this.loadDataDia();                                                
            },
            convenioIdAgendar(newvalue, oldvalue) {
                this.convenioAnterior = oldvalue;
                this.convenioIdAgendar = newvalue;
                if(!newvalue || newvalue==null){
                    this.listaExames = [];                     
                }                          
                this.procurarExameTexto = null; 
                if (oldvalue == null || newvalue != oldvalue && newvalue > 0 && oldvalue > 0 && (this.reagendamento != null || (this.examesSelecionados != null && this.examesSelecionados.length > 0))) {
                    this.alterouConvenio();                 
                } 
            },
         
            filtrosAgendamentoId: function(value) {                
                if(value && value.length > 0){
                    if(this.convenioIdAgendar == "" || this.convenioIdAgendar == null){
                        this.permiteSalvar = false;
                        this.toastInserirConvenio();                        
                    }
                    else {              
                        this.loadDataDia();
                    }                    
                }else{     
                    this.agendas = [];
                    this.eventosSalvar = [];
                }                
            },            
            examesLaboratorial: function() {
                this.examesSelecionados = [];
                this.filtrosAgendamentoId = [];
                this.eventosSalvar = [];

                if(this.examesLaboratorial)            
                    this.loadTiposAgendamentos();                
                
            },    
            examesSelecionados: function() {                
                if(this.examesSelecionados.length > 0){
                    this.loadTiposAgendamentos();                    
                    
                }else{                    
                    this.tiposAgendamentoAtivos = [];                                    
                    this.eventosSalvar = [];   
                    this.filtrosAgendamentoId = [];                 
                }
                this.procurarExameTexto = null;
            },        
        },        
        data() {
            return {                                                     
                filtrosAgendamentoId: [],
                tiposAgendamentoAtivos: [],
                agendas: [],                         
                examesSelecionados: [],                
                isLoading: false,
                examesLaboratorial: false,
                eventosSalvar:[],          
                dataDiaFiltro: new Date(new Date().setHours(0,0,0,0)),
                convenioIdAgendar: null, 
                executanteIdAgendar:null, 
                convenioExameCodigoTipoAgendar: null,
                incluindoExameIndex: 0,
                procurarExamePor: localStorage.procurarExamePorInspecao ?? 'Nome',
                procurarExameTexto: '',
                mostraDetalhesExameId: null,       
                isExterno: false, 
                listaExames: null,  
                observacoesInspecao:null,
                contador: 1,                
                reagendamento: this.$route.params?.reagendamento ?? null,     
                verificaExamesSelecionados: [],  

                listaPacienteAutocomplete: [],
                buscaPaciente: null,
                selected: null,
                isFetching: false,
                paciente: {
                    id: null,
                    nome: null,
                    cpf: null,
                    sexo: null,
                    dataDeNascimento: null,
                    peso: null,
                    inscricao: null,
                    inscricaoDV: null,
                    email: null,
                    celular: null
                },
                pacienteFoiSalvo: false,
                botaoProximoDiaVago: false,
                botaoProximoDiaVagoTodos: false,
                dia: 0,
                permiteSalvar: false,
                examesBloqueados: [],
                retornoSucesso: true,
                convenioAnterior: null,
                inserirConvenio: false,
                tipoAgendamentoDisabled: false,
            }
        },
        created() {
            if (this.reagendamento) {
                this.reagendamento.pacienteDataNascimento = moment(this.reagendamento.pacienteDataNascimento).toDate();
            }
        },
        mounted() {        
            if(this.reagendamento){
                this.paciente.nome = this.reagendamento.pacienteNome;
                this.paciente.cpf = this.reagendamento.pacienteCpf;
                this.paciente.email = this.reagendamento.pacienteEmail;
                this.paciente.sexo = this.reagendamento.pacienteSexo;
                this.paciente.dataDeNascimento = moment(this.reagendamento.pacienteDataNascimento).toDate();
                this.paciente.peso = this.reagendamento.pacientePeso;
                this.paciente.inscricao = this.reagendamento.pacienteInscricao;
                this.paciente.inscricaoDV = this.reagendamento.pacienteInscricaoDV;
                this.paciente.celular = this.reagendamento.pacienteCelular;
                this.paciente.id = this.reagendamento.pacienteId;

                if(this.reagendamento.convenioId>0)
                    this.convenioIdAgendar = this.reagendamento.convenioId;

                if(this.reagendamento.guiaAgendaExames && this.reagendamento.guiaAgendaExames.length>0 ){
                    
                    const examesSelecionadosReagendar = [];
                    
                    this.reagendamento.guiaAgendaExames.forEach(e => {
                        examesSelecionadosReagendar.push({                    
                            id: e.id,
                            nome: e.apelido
                        });                     
                    });    
                    
                    this.examesSelecionados = examesSelecionadosReagendar;
                }                
                

            }

            this.$on('preencheu-disponibilidades', async (disponibilidades, examesIds) => {

                if (disponibilidades[0].encaixe === false)
                    try
                    {
                        const eventoId = await this.reservaTemporaria(disponibilidades[0], examesIds);                                
                        if (eventoId)
                            disponibilidades.forEach(disponibilidade => {
                                disponibilidade.id = eventoId
                                disponibilidade.finalizarReserva = true
                            })

                    } catch (error)
                    {
                        this.desfazEventoSelecionado(disponibilidades[0])
                        this.$buefy.toast.open({
                            duration: 10000,
                            message: error.message,
                            type: 'is-warning',
                            queue: false
                        }) 
                    }
            })

        }, 
        methods: {

            confirmarEncaixe(agenda){
                     
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlterarDataEncaixe,
                    events: {
                        encaixePaciente: this.encaixePaciente
                    },   
                    props: {
                        agenda: agenda,
                        desabilitarDia: true,
                        data: agenda.dataDiaFiltro
                    },                                    
                    hasModalCard: true,
                    trapFocus: true
                })

                return;
            },    
            
        encaixePaciente(agenda, novaData) {
            const novoEventoEncaixe = { ...agenda.eventosDia[0] };
            novoEventoEncaixe.id = 0;
            novoEventoEncaixe.observacao = null;
            novoEventoEncaixe.selecionado = true;
            novoEventoEncaixe.bloquearAgenda = false;
            novoEventoEncaixe.descricaoExame = "";
            novoEventoEncaixe.dataHora = moment(novaData).format('YYYY-MM-DDTHH:mm:ss');
            novoEventoEncaixe.data = moment(novaData);                 
            novoEventoEncaixe.hour = moment(novaData).format('HH:mm');
            novoEventoEncaixe.encaixe = true;
            novoEventoEncaixe.funcionarioId = (this.funcionarioId) ? this.funcionarioId : null;
            novoEventoEncaixe.pacienteNome = null;
            novoEventoEncaixe.convenioNome = null;
            novoEventoEncaixe.encaixeNaoSalvo = true;
            const dataHoraAtual = moment();
            const novaDataMoment = moment(novaData);

            const horarioFuturo = novaDataMoment.isAfter(dataHoraAtual);

            const conflito = agenda.eventosDia.some(evento =>
                   evento.hour === novoEventoEncaixe.hour &&
                   (evento.pacienteNome !== null || (horarioFuturo && moment(evento.dataHora).isAfter(dataHoraAtual)))
            );

            if (conflito) {
              this.$buefy.toast.open({
                  duration: 10000,
                  message: this.$t('AGENDA.NAOEXISTEHORARIOLIVREENCAIXE'),
                  type: 'is-warning',
                  queue: false
              });
               return false;
            }

            agenda.eventosDia.push(novoEventoEncaixe);
            agenda.eventosDia = agenda.eventosDia.sort((a, b) => (a.dataHora > b.dataHora) ? 1 : -1);

            this.verificarAgenda(novoEventoEncaixe);
            this.$forceUpdate();        
            return true;
        },
      
            inputListaExames: debounce(function () {
                this.loadListaExames()
            }, 500),
            async atualizaEventosSalvar() {                    
                
                this.eventosSalvar.forEach(e => { 
                    
                    const agenda = this.agendas.filter(a=> a.tipoAgendamentoId == e.evento.tipoAgendamentoId)[0];                    
                    if(agenda){                        
                        
                        const evento = agenda.eventosDia.filter(a=> a.dataHora == e.evento.dataHora)[0];                        
                        if(evento){
                            evento.selecionado=true;
                            evento.bloquearAgenda = true;
                            evento.descricaoExame = e.evento.descricaoExame;                            
                        }                                                             
                    }
                });
                           
                if(this.eventosSalvar.length > 0) {                    
                    this.VerificarBloqueioAgendas();                    
                }                                           
                                    
                
            },            
            escolheuConvenio(val) {    
                if(val !== null) {
                    if (val.data === null) {
                        if (this.tiposAgendamentoAtivos !== null || this.examesSelecionados !== null) {
                            this.toastInserirConvenio();
                        }
                    }
                    else {
                        if(val.data !== undefined && val.data.exameCodigoTipoId > 0)
                            this.convenioExameCodigoTipoAgendar = val.data.exameCodigoTipoId;    
                    }
                }
            },
            toastInserirConvenio() {
                this.$buefy.toast.open({
                    duration: 3000,
                    message: this.$t('AGENDA.INSERIRCONVENIO'),
                    type: 'is-warning',
                    queue: false
                })
            },          
            async escolheuExecutante(val) {    
                if(!val.nome)
                    this.executanteIdAgendar=null;

                this.agendas.forEach(a => {
                    this.carregarDataDia(a);    
                });                     
                
            },            
            mostraDetalhes(id, event) {
                event.stopPropagation();
                if (this.mostraDetalhesExameId == id) {
                    this.mostraDetalhesExameId = null;
                }
                else {
                    this.mostraDetalhesExameId = id;
                }
            },            
            loadListaExames() {
                if (this.convenioIdAgendar) {
                    const params = {
                        texto: this.procurarExameTexto,
                        campo: this.procurarExamePor,                        
                        convenioId: this.convenioIdAgendar,
                        exameCodigoTipoId: this.convenioExameCodigoTipoAgendar
                    }
                    this.$http.get('/api/atendimento/EditGuiaListaExames', { params })
                        .then(res => {
                            this.listaExames = res.data
                            this.incluindoExameIndex = 0
                            this.$refs.procurarExameTextoInput.focus()
                        })
                }else{
                    this.listaExames = [];
                }
            },       
            async incluirExame(exame) {
                if(exame.bloqueado){
                    return;
                }

                const dataDeSolicitacao = new Date();
                let msgDiasRealizacao = "";

                exame.listDiasRealizacao?.forEach(dia => {
                    switch (dia) {
                        case 0:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.DOMINGO')}&nbsp;&nbsp;`;
                            break;
                        case 1:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.SEGUNDA')}&nbsp;&nbsp;`;
                            break;
                        case 2:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.TERCA')}&nbsp;&nbsp;`;
                            break;
                        case 3:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.QUARTA')}&nbsp;&nbsp;`;
                            break;
                        case 4:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.QUINTA')}&nbsp;&nbsp;`;
                            break;
                        case 5:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.SEXTA')}&nbsp;&nbsp;`;
                            break;
                        case 6:
                            msgDiasRealizacao += `&nbsp;&nbsp;${this.$t('GUIAATENDIMENTO.SABADO')}&nbsp;&nbsp;`;
                            break;
                        default:
                            throw new Error('Index out of range');
                    }
                });

                const id = exame.id ? exame.id : exame.exameId;

                if(this.examesSelecionados.filter(x => x.exameId == id && x.exameDePerfil).length > 0){
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.BLOQUEIAEXAMEPERFIL'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMBLOQUEIOPERFIL'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                }
                else if(this.examesSelecionados.filter(x => x.id == id).length > 0){
                    return null;                               
                } 
                else {
                    this.examesSelecionados.push({                    
                        id: id,
                        nome: exame.apelido,
                    });                    
                }

                this.$refs.procurarExameTextoInput.focus();
            },
            confirmarAlteracaoDataAgendas(agenda){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlterarDataAgendamento,
                    events: {
                        alteracaoDataAgendas: this.alteracaoDataAgendas
                    }, 
                    props: {
                        agenda:agenda
                    },                                                    
                    hasModalCard: true,
                    trapFocus: true
                })

                return;
            }, 
            
            eventoSelecionado(){
                const eventosSelecionados = [];

                // Filtrar os eventos selecionados
                this.agendas.forEach(a => {
                        const agendaSelecionada = a.eventosDia.filter(x=>x.selecionado)[0]; 
                        
                        if(agendaSelecionada){                            
                            eventosSelecionados.push(agendaSelecionada);                 
                        }
                });

                return (eventosSelecionados.length > 0);
            },            
            async cancelarAgendamentos(){   
                const eventosParaDesfazer = this.eventosSalvar.map(registroParaSalvar => registroParaSalvar.evento)
                const promessasDesfazer = eventosParaDesfazer.map(evento => this.desfazEventoSelecionado(evento))
                await Promise.all(promessasDesfazer)
                // TODO: Não é a  melhor forma de tratar, mas atualmente como está estruturado o frontend da agenda, necessário buscar escala
                // novamente quando clica no cancelar. Seria necessário refazer a forma como está sendo apresentado
                this.loadDataDia()
            },

            async buscarRegrasAgendamento(examesIds,evento){
                
                let quantidadeSlots;
                
                await this.$http.get('/api/agenda/BuscarRegrasAgendamento{?executanteId,tipoAgendamentoId,examesIds*}', {
                    params: {              
                        tipoAgendamentoId: evento.tipoAgendamentoId,  
                        executanteId: evento.executanteId,          
                        examesIds: examesIds.map(x => x)
                    }
                }).then(res => res.data).then(data => {                        
                        quantidadeSlots=data;
                }).catch(e => {
                            throw e;
                });

                return quantidadeSlots;

            },              
            async verificarRegrasSlot(examesIds,evento){
                                                
                const quantidadeSlots = await this.buscarRegrasAgendamento(examesIds,evento);
                
                const dataHoraFim = this.$moment(evento.dataHora).add( (evento.intervaloDuracaoAtendimento * quantidadeSlots), 'm');
                evento.dataHoraFim = dataHoraFim;   
                
                //Quando tiver N exames, N slots
                if(quantidadeSlots > 1 && !evento.encaixe){
                    this.agendas.filter(a=> a.tipoAgendamentoId == evento.tipoAgendamentoId).forEach(a => { 

                        
                        const escalasEncontradas =  a.eventosDia.filter(e=> this.$moment(e.dataHora) < dataHoraFim 
                                                                        && this.$moment(e.dataHora) >= this.$moment(evento.dataHora) 
                                                                        && e.id==0);                         
                   
                        if(escalasEncontradas.length < quantidadeSlots ){

                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message:  this.$t('AGENDA.NAOEXISTEHORARIOLIVRE'),
                                    type: 'is-warning',
                                    queue: false
                                });
                                
                                evento.selecionado=false;                                                                                                                            
                             
                        }else{  
                            
                            //Selecionar eventos dos slots
                            escalasEncontradas.forEach(x => { 
                                const escala = a.eventosDia.filter(e=>e.hour== moment(x.dataHora).format('HH:mm'))[0];
                                this.atualizaEventoSelecionado(escala,examesIds);     
                            });  
                                                        
                            this.eventosSalvar.push({
                                examesIds: examesIds,
                                evento: evento
                            });                   
                            this.$emit('preencheu-disponibilidades', escalasEncontradas, examesIds) 
                        }

                    });
                    
                //1 unico exame, 1 slot
                }else{                
                    this.atualizaEventoSelecionado(evento,examesIds); 
                                        
                    this.eventosSalvar.push({
                        examesIds: examesIds,
                        evento: evento
                    });
                    this.$emit('preencheu-disponibilidades', [evento], examesIds)                                                           
                }

                                  
                this.VerificarBloqueioAgendas(); 
                
            },
            atualizaEventoSelecionado(evento,examesIds){
                evento.chaveIndex = this.eventosSalvar.length + 1;
                evento.selecionado= true;
                evento.descricaoExame = this.examesSelecionados.filter(x=> examesIds.includes(x.id) ).map(e => e.nome).join(" , ");                      
            },
            async desfazEventoSelecionado(evento)
            {
                try
                {
                    if (evento.id > 0)await this.cancelarReserva(evento)
                    this.eventosSalvar = this.eventosSalvar.filter(e=>e.evento.chaveIndex != evento.chaveIndex);    
                    evento.id = 0        
                    evento.chaveIndex = null;
                    evento.selecionado = false;
                    evento.descricaoExame="";   
                    await this.agendas.filter(a=> a.tipoAgendamentoId == evento.tipoAgendamentoId).forEach(a => {
                        a.eventosDia.forEach(d => {  
                            // TODO: O mais apropriado é tratar esse status de reservado no backend e retirar da observação
                            if (['Reservado', 'RESERVADO_TEMPORARIAMENTE'].includes(d.observacao)) return;
                            d.id = 0                           
                            d.bloquearAgenda = false;   
                            d.selecionado = false; 
                            d.descricaoExame="";      
                            d.chaveIndex = null;                                                    
                        });                                                     
                    });

                    await this.VerificarBloqueioAgendas(); 
                } catch (error)
                {
                    evento.selecionado = true;
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: error.message,
                        type: 'is-warning',
                        queue: false
                    }) 
                }
            },
            async verificarAgenda(evento){                                
                if(this.examesSelecionados.length<=0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDA.NENHUMEXAMESELECIONADO'),
                        type: 'is-warning',
                        queue: false
                    });
                    evento.selecionado = false;
                    return true;
                }

                //fazer remocao do evento
                if(evento.chaveIndex >0){     
                    await this.desfazEventoSelecionado(evento)
                    return true;
                }

                this.isLoading = true;

                if(this.observacoesInspecao)
                    evento.observacao = this.observacoesInspecao;   
                
                this.verificaExamesSelecionados = [];

                if (this.examesSelecionados.length >= 2) {
                    const dia = this.getDayNumberOfWeek(this.agendas.filter(a=> a.tipoAgendamentoId == evento.tipoAgendamentoId)[0].eventosDia[0].dataDiaFiltro);

                    await Promise.all(this.examesSelecionados.map(async ex => {
                        const url = `/api/agenda/BuscarRegrasSlotZeroExame?exameId=${ex.id}&tipoAgendamentoId=${evento.tipoAgendamentoId}&dia=${dia}`;
                    
                        const res = await this.$http.get(url);
                        const possuiSlotZero = res.data.possuiRegraSlotZero;
                        this.verificaExamesSelecionados.push({
                            id: ex.id,
                            nome: ex.nome,
                            possuiSlotZero,
                        });
                    }));
                }
                else {
                    this.examesSelecionados.map(ex => {
                         this.verificaExamesSelecionados.push({
                            id: ex.id,
                            nome: ex.nome,
                            possuiSlotZero: false,
                        });
                    })
                }
                
                await this.agendas.filter(a=> a.tipoAgendamentoId == evento.tipoAgendamentoId).forEach(async a => {
                                   
                        const equipments = a.eventosDia[0].equipments;                         
                        const testCodes = [];

                        //Em cada agenda pode ter N equipamentos
                        //Adiciona todos testCodes feitos pela Agenda                        
                        equipments.forEach(q => {
                            q.testCodeConfig.forEach(t => {                                
                                testCodes.push(t);
                            });
                            
                        });

                        //verificar se algum exame selecionado ja foi agendado
                        const examesIds = [];

                        this.verificaExamesSelecionados.filter(e => e.possuiSlotZero === false).map(e => parseInt(e.id)).forEach(exameId=>{  
                            if(testCodes.filter(t=>t.lisTestCode == exameId).length>0){
                                let NaoAgendado = true;                          
                                this.eventosSalvar.forEach(evento => {                                    
                                    if(evento.examesIds.filter( ex => ex == exameId).length>0)
                                        NaoAgendado = false;
                                })

                                if(NaoAgendado)
                                    examesIds.push(exameId);                                    
                            }

                        });
                        
                        if(examesIds.length>0){
                            this.verificarRegrasSlot(examesIds,evento);  
                            
                        }else{
                            setTimeout(() => {
                                evento.selecionado=false;
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: this.$t('AGENDA.EXAMESJAAGENDADOS'),
                                    type: 'is-warning',
                                    queue: false
                                })                                
                            },500);    
                        }                        
                                                                                        
                });
                                
                await this.VerificarBloqueioAgendas();                                        
                
                this.isLoading = false;
                                
            },     
            async VerificarBloqueioAgendas(agenda){
                
                //Se todos exames ja agendados, bloquear todas agendas
                if(await this.todosExamesAgendados()){
                        this.agendas.forEach(a => {                                            
                            a.eventosDia.filter(e=>!e.selecionado).forEach(a => {                                                                   
                                a.bloquearAgenda = true;                        
                            });                        
                        });                
                }else{

                    this.agendas.forEach(a => {       
                        
                        if(this.eventosSalvar.filter(e=>e.evento.tipoAgendamentoId == a.tipoAgendamentoId ).length>0){
                            a.eventosDia.filter(e=>!e.selecionado).forEach(d => {                                                                   
                                d.bloquearAgenda = true;                        
                            });                             
                        }
                        
                    });  
                }                
            },
            alteracaoDataAgendas(novaData,agenda){
                if(agenda){
                    agenda.dataDiaFiltro =  new Date(novaData.setHours(0,0,0,0));               
                    agenda.proximo = 0; 

                    this.carregarDataDia(agenda);
                    
                }else{
                    this.agendas.forEach(a => {
                        a.proximo = 0; 
                        a.dataDiaFiltro =  new Date(novaData.setHours(0,0,0,0));     
                        this.carregarDataDia(a);    
                    }); 
                    
                }                               
            }, 
            proximoDiaTodos(dia){    
                this.agendas.forEach(a => {
                    a.dataDiaFiltro.setDate(a.dataDiaFiltro.getDate() + dia);                                                
                    a.proximo = 0;                 
                    this.carregarDataDia(a);                                                               
                }); 
            },      
            proximoDiaVagoTodos(proximo){      
                this.botaoProximoDiaVago = true;    
                this.botaoProximoDiaVagoTodos = true;   
                if(proximo){
                    this.dia = 1;
                } else {
                    this.dia = -1;
                }                              
                this.isLoading = true;
                try {
                        this.$http.get('/api/agenda/ProximoDiaVagoTodos{?dataDiaFiltro,tipoAgendamentoIds*}', {
                            params: {              
                                dataDiaFiltro: this.$moment(this.agendas[0].dataDiaFiltro).format('YYYY-MM-DDTHH:mm:ss'),                                      
                                tipoAgendamentoIds: this.agendas.map(x => x.tipoAgendamentoId),
                                examesId: this.examesSelecionados.map(item => item.id).join(),
                                unidadeId: this.$route.query.unidadeId,
                                executanteId: this.executanteIdAgendar,
                                proximo: proximo                  
                            }
                        })
                        .then(res => res.data)
                        .then(data => {    
                            if(data.dataProximoDiaVago != null){
                                this.agendas.forEach(a => {
                                    this.alteracaoDataAgendas(new Date(data.dataProximoDiaVago),a);
                                });                           
                            }else{
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: this.$t('AGENDA.PROXIMODIAVAGONAOFOIPOSSIVEL'),
                                    type: 'is-warning',
                                    queue: false
                                });
                            }
                            this.botaoProximoDiaVago = false;       
                            this.botaoProximoDiaVagoTodos = false;
                        }).catch(e => {
                            throw e;
                        });                        
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }                
            },                                                                  
            proximoDia(dia,agenda){                              
                agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() + dia);                                                
                agenda.proximo = 0;                 
                this.carregarDataDia(agenda);                                                               
            }, 
            proximoDiaVago(dia,agenda){                   
                this.botaoProximoDiaVago = true;                
                agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() + dia);
                agenda.proximo = dia;                   
                this.carregarDataDia(agenda);                       
            },                  
            carregarDataDia(agenda){ 
               
                this.isLoading = true;                
                const params = [];                
                if (agenda.dataDiaFiltro != null) params.push(`dataDiaFiltro=${this.$moment(agenda.dataDiaFiltro).format('YYYY-MM-DDTHH:mm:ss')}`);
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if (agenda.tipoAgendamentoId) params.push(`tipoAgendamentoId=${agenda.tipoAgendamentoId}`);                
                if (this.executanteIdAgendar) params.push(`executanteId=${this.executanteIdAgendar}`);   
                if (this.examesSelecionados.length > 0) params.push('examesId=' + this.examesSelecionados.map(item => item.id).join());
                params.push(`botaoProximoDiaVago=${this.botaoProximoDiaVago}`);

                this.$http.get(`/api/agenda/AgendarDia?${params.join('&')}`)
                .then(res => res.data)
                .then(data => {
                    agenda.eventosDia = data?.eventos                                    
                        .map(d => {
                            const agendamentoEmAndamentoParaEsseSlot = this.obterAgendamentoCandidatoAoAgendamento(d);
                            if (agendamentoEmAndamentoParaEsseSlot?.evento) return agendamentoEmAndamentoParaEsseSlot.evento
                            return this.getEvento(d);
                        });
                        if(!this.botaoProximoDiaVagoTodos){
                            this.isLoading = false;       
                        }
                    this.$forceUpdate();                          
                })
                .finally(() => {                              
                    if(agenda.eventosDia.length==0){
                        if(agenda.proximo==0){
                            if(this.dia > 0){
                                this.proximoDiaVagoTodos(true);
                            }
                            if(this.dia < 0){
                                this.proximoDiaVagoTodos(false);
                            }
                        }
                        if(this.contador > 60){
                            this.contador=1;
                        }else if(agenda.proximo>0){
                            agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() + 1); 
                            this.contador++;
                            this.carregarDataDia(agenda);
                        }else if (agenda.proximo<0){
                            agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() - 1); 
                            this.contador++;
                            this.carregarDataDia(agenda);
                        }                                                                                
                        return;                                
                    }                    
                    if(!this.botaoProximoDiaVagoTodos)
                        this.botaoProximoDiaVago = false;     

                    this.atualizaEventosSalvar(); 
                    this.$forceUpdate(); 
                });                
                                
            },      

            loadDataDia(){
                const params = [];
                              
                if (this.dataDiaFiltro != null) params.push(`dataDiaFiltro=${this.$moment(this.dataDiaFiltro).format('YYYY-MM-DDTHH:mm:ss')}`);                                     
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if (this.examesSelecionados.length > 0) params.push('examesId=' + this.examesSelecionados.map(item => item.id).join());
                if (this.filtrosAgendamentoId.length > 0) params.push('filtroTipoAgendamento=' + this.filtrosAgendamentoId.map(item => item).join());
                
                this.isLoading = true;

                try {
                    this.$http.get(`/api/agenda/Agendar?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {    

                                data.forEach((a, index) => {
                                    a.eventosDia = a.eventosDia.map(d => { 
                                        const agendamentoEmAndamentoParaEsseSlot = this.obterAgendamentoCandidatoAoAgendamento(d);
                                        if (agendamentoEmAndamentoParaEsseSlot?.evento) return agendamentoEmAndamentoParaEsseSlot.evento
                                        return this.getEvento(d); 
                                    });
                                    a.dataDiaFiltro =  new Date(this.$moment(a.dataDiaFiltro).toDate().setHours(0,0,0,0));
                                });                                
                                
                                this.agendas = data;
                                
                            }                            
                        }).catch(e => {
                            throw e;
                        }).finally(() => {  
                            this.atualizaEventosSalvar();                                  
                            this.$forceUpdate(); 
                            this.isLoading = false;
                        });
                        
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }

            },     
            obterAgendamentoCandidatoAoAgendamento(evento)
            {
                return this.eventosSalvar.find(candidato => candidato.evento.id == evento.id)
            },                                      
            getDayOfWeek(dataDiaFiltro) {
                const day = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
                return day[this.$moment(dataDiaFiltro).toDate().getDay()];
            },
            getDayNumberOfWeek(dataDiaFiltro) {                                                 
                const day = this.getDayOfWeek(dataDiaFiltro);
                let dayNumber = 0;
                switch(day) {
                    case "Domingo":
                        dayNumber = 0;
                        break;
                    case "Segunda":
                        dayNumber = 1;
                        break;
                    case "Terça":
                        dayNumber = 2;
                        break;
                    case "Quarta":
                        dayNumber = 3;
                        break;
                    case "Quinta":
                        dayNumber = 4;
                        break;
                    case "Sexta":
                        dayNumber = 5;
                        break;
                    default:
                        //sabado
                        dayNumber = 6;
                        break;
                }
                return dayNumber ;
            }, 
            getEvento(d) {
                return {
                    ...d,
                    atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                    atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                    day: moment(d.dataHora).format('YYYY-MM-DD'),
                    hour: moment(d.dataHora).format('HH:mm'),
                    data: moment(d.dataHora),
                    selecionado: false,
                    bloquearAgenda: false,
                    descricaoExame: ""
                }
            },
            loadTiposAgendamentos(){
                const params = [];
                
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if (this.examesSelecionados.length > 0) params.push('examesId=' + this.examesSelecionados.map(item => item.id).join());                
                
                this.isLoading = true;

                try {
                    this.$http.get(`/api/agenda/tiposAgendamentoAtivos?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {                 
                            if(this.examesLaboratorial){
                                this.tiposAgendamentoAtivos = data.filter(x=>x.testCodes.length==0);                            
                            }else{
                                this.tiposAgendamentoAtivos = data.filter(x=>x.testCodes.length>0);                             
                            }                                                                                                                                  
                        }).catch(e => {
                            throw e;
                        }).finally(() => {  
                            this.atualizaEventosSalvar();                                  

                            //Tratamento quando tem vários exames selecionados e remove algum, para remover tambem as agendas vinculadas aquele exame removido
                            this.filtrosAgendamentoId = this.filtrosAgendamentoId.filter( f=> this.tiposAgendamentoAtivos.map(e => parseInt(e.id)).includes(f) );
                            
                            this.$forceUpdate(); 
                            this.isLoading = false;
                        });
                        
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }

            },            
           
            tipoAgendamentoStyle(tipo) {
                return 'color: #' + tipo.cor + '!important'
            },
            cancelarReagendamento(){
                this.reagendamento = null;
                this.examesSelecionados=null;
            },
            async salvarAgendamentos(){  
                if (!((this.paciente?.id || this.paciente?.nome) || this.reagendamento?.id))
                {
                    try
                    {
                        for(let i = 0; i < this.eventosSalvar.length; i++){
                            await this.reservaDuradoura(this.eventosSalvar[i].evento);                            
                        }
                        const parametrosParaRedirecionamento = {procurar: null, criarGuia: !this.config.naoGerarGuiaAposInspecao, 
                        agendamento: this.eventosSalvar, observacoesInspecao: this.observacoesInspecao, convenioIdAgendar: this.convenioIdAgendar, agendamentoDashboard:false }                                
                        this.redirecionarParaListagemDePacientes(parametrosParaRedirecionamento) 
                    } catch (error)
                    {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: `${error.message}`,
                            type: 'is-warning',
                            queue: false
                        });
                    }

                    return;
                }

                if(this.convenioIdAgendar == null){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDA.CONVENIOOBRIGATORIO'),
                        type: 'is-warning',
                        queue: false
                    });
                    return true;
                }

                if(this.eventosSalvar<=0 && !this.permiteSalvar){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDA.NENHUMHORARIOSELECIONADO'),
                        type: 'is-warning',
                        queue: false
                    });
                    return true;
                }

                //Verifica se todos exames selecionados foram agendados                
                if(!(await this.todosExamesAgendados())){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('AGENDA.EXAMESNAOAGENDADOS'),
                                type: 'is-warning',
                                queue: false
                            });
                }else{

                    //Verifica se tem agendamentos em um mesmo horario para um mesmo executante em agendas diferentes
                    const foiAgendadoMesmoHorarioExecutante = this.eventosSalvar.every((item, index, arr) => {
                        if(index === 0)
                            return true;
                        else
                            return item.evento.executanteId === arr[index-1].evento.executanteId && 
                                   item.evento.dataHora === arr[index-1].evento.dataHora &&
                                   item.evento.tipoAgendamentoId != arr[index-1].evento.tipoAgendamentoId;
                    });

                    const agendadoMesmoHorario = this.eventosSalvar.every((item, index, arr) => {
                        if(index === 0)
                            return true;
                        else
                            return item.evento.dataHora === arr[index-1].evento.dataHora &&
                                   item.evento.tipoAgendamentoId != arr[index-1].evento.tipoAgendamentoId;
                    });

                    //Se tiver mais de um agendamento para ser feito, e se foram agendados 
                    //no mesmo horario para o mesmo executante em agendas diferentes, exibe erro
                    if(this.eventosSalvar.length > 1 && foiAgendadoMesmoHorarioExecutante){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('AGENDA.AGENDADOMESMOHORARIOEXECUTANTE'),
                            type: 'is-warning',
                            queue: false
                        });
                        return true;
                    }

                    if(this.eventosSalvar.length > 1 && agendadoMesmoHorario){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('AGENDA.AGENDADOMESMOHORARIO'),
                            type: 'is-warning',
                            queue: false
                        });
                        return true;
                    }

                    const camposValidos = await this.validaCamposPaciente();
                    if (!camposValidos){
                        return
                    }
                    let agendamentosComSucesso = true;     
                    if(this.eventosSalvar.length>0){
                        const pacienteSelecionado = this.paciente.nome !== null
                        if (pacienteSelecionado)
                        {
                            await this.salvarPaciente();                                     
                            if(!this.pacienteFoiSalvo)
                                return;
                        }
                        localStorage.procurarExamePorInspecao = this.procurarExamePor;                        
                                                          
                        for(let i = 0; i < this.eventosSalvar.length; i++){
                            const incluirNovoHorarioDiaRetorno =  await this.incluirNovoHorarioDia(this.eventosSalvar[i].evento,this.eventosSalvar[i].evento.dataHora, false, this.eventosSalvar[i].examesIds);                            
                            
                            if(!incluirNovoHorarioDiaRetorno)
                                agendamentosComSucesso = false;
                                                            
                        }

                        //Se reagendamento não redireciona
                        if (this.reagendamento && this.reagendamento.id ) {
                            this.reagendamento = null;
                            this.$buefy.toast.open({
                                duration: 10000,
                                message:  "Reagendamento realizado com sucesso",
                                type: 'is-success',
                                queue: false
                            });            
                            this.permiteSalvar = false;
                            this.eventosSalvar = [];

                        }else{       
                            if(agendamentosComSucesso){  

                                if(pacienteSelecionado){                                    
                                    await this.$http.get('/api/manutencao/paciente?id=' + this.paciente.id)
                                                .then(res => {
                                                this.paciente = res.data;

                                                if(this.paciente.dataDeNascimento){
                                                    this.paciente.dataDeNascimento = new Date(this.paciente.dataDeNascimento)
                                                }
                                                
                                                this.buscaPaciente = null
                                                })

                                    if(this.config.naoGerarGuiaAposInspecao){
                                        if(this.eventosSalvar && this.eventosSalvar.length>0) {
                                            let modeloItem = "";

                                            this.eventosSalvar.forEach(a => {

                                                const params = [];

                                                params.push(`pacienteId=${this.paciente.id}`)
                                                params.push(`id=${a.evento.id}`); 
                                                if(a.evento.executanteId != null && a.evento.executanteId > 0){
                                                    params.push(`funcionarioId=${a.evento.executanteId}`);
                                                }

                                                if(this.observacoesInspecao)
                                                    params.push(`observacao=${this.observacoesInspecao}`);
                                                        
                                                const url = `/api/agenda/agendaEditar?${params.join('&')}`;
                                                
                                                this.$http.post(url)
                                                    .then(res => res.data)
                                                    .then(data => {
                                                        if (data.comprovanteModelos && data.comprovanteModelos.length > 0) {
                                                            modeloItem = data.comprovanteModelos[0];
                                                            if(modeloItem != ""){
                                                                this.$buefy.modal.open({
                                                                    parent: this,
                                                                    component: modalAgendamentoConcluido,
                                                                    props: {
                                                                        pacienteEmail: this.paciente.responsavelEmail ?? this.paciente.email,
                                                                        pacienteNome: this.paciente.nome,
                                                                        modeloNome: modeloItem.nome,
                                                                        eventosSalvar: this.eventosSalvar,
                                                                    },
                                                                    hasModalCard: true,
                                                                    trapFocus: true,
                                                                    on: {
                                                                        'hook:mounted'() {
                                                                            document.addEventListener('keydown', this.onKeydown);
                                                                        },
                                                                        'hook:beforeDestroy'() {
                                                                            document.removeEventListener('keydown', this.onKeydown);
                                                                        },
                                                                    },
                                                                })
                                                                
                                                            }
                                                        }
                                                    
                                                        if (data.mensagem != null && data.mensagem != "") {
                                                            this.$buefy.toast.open({
                                                                duration: 5000,
                                                                message: data.mensagem,
                                                                type: 'is-danger',
                                                                queue: false
                                                            })
                                                        }
                                                    });
                                            });
                                        }
                                    }
                                    else{
                                        this.$router.push({
                                            name: 'paciente', params: {
                                                id: this.paciente.id,
                                                criarGuia: !this.config.naoGerarGuiaAposInspecao,
                                                agendamento: this.eventosSalvar,
                                                observacoesInspecao: this.observacoesInspecao,
                                                convenioIdAgendar: this.convenioIdAgendar,
                                                agendamentoDashboard:false
                                            }
                                        })
                                    }
                                }else{
                                    if(this.reagendamento == null){
                                        this.redirecionarParaListagemDePacientes()                           
                                    }
                                }
                            }else{
                                this.cancelarAgendamentos();
                            }                            
                            
                        }


                        
                    } 
                    else if(this.reagendamento && this.reagendamento.id){
                        if(this.reagendamento.dataHoraFim == null){
                            this.reagendamento.dataHoraFim = this.$moment(this.reagendamento.dataHora).add(this.reagendamento.intervaloDuracaoAtend, 'm').toDate();
                        }
                        await this.incluirNovoHorarioDia(this.reagendamento,this.reagendamento.dataHora, true, this.reagendamento.examesIds);
                    }                                   
                }
                              
            },    
            async todosExamesAgendados(){
                
                let retorno = true;

                this.verificaExamesSelecionados.map(e => parseInt(e.id)).forEach(exameId=>{  
                    let NaoAgendado = true; 
                                                                                    
                    this.eventosSalvar.forEach(evento => {                                                            
                        const existeAgendamento = evento.examesIds.filter( ex => ex == exameId).length;                            
                        if(NaoAgendado && existeAgendamento>0){                                                                            
                            NaoAgendado = false;
                        }                            
                    });

                    if(NaoAgendado)
                        retorno = false;

                });
                
                return retorno;

            },        
            redirecionarParaListagemDePacientes(params)
            {
                this.$router.push({ name: 'pacientes', params: params }) 
            },
            async incluirNovoHorarioDia(evento, horario,bloqueio, examesIds){      
                const params = {};
                params.intervaloDuracaoAtendimento = evento.intervaloDuracaoAtendimento

                if (evento.tipoAgendamentoId != null) 
                    params.tipoAgendamentoId = evento.tipoAgendamentoId;

                if (horario != null) 
                    params.horario = moment(horario).toDate(); 

                if(bloqueio){
                    this.adicionarAgendamento(evento, false, examesIds, bloqueio);
                    return;
                }

                //Novo paciente redirecionamento listagem paciente
                if(evento.equipments.length > 0) {
                    const incluirNovoHorarioDiaRetono =  await this.adicionarAgendamento(evento, true, examesIds, false);                                                        
                    return incluirNovoHorarioDiaRetono;
                } //Novo paciente Padrão
                else{    
                    const parametrosParaRedirecionamento = {procurar: null, criarGuia: !this.config.naoGerarGuiaAposInspecao, 
                    agendamento: this.eventosSalvar, observacoesInspecao: this.observacoesInspecao, convenioIdAgendar: this.convenioIdAgendar, agendamentoDashboard:false }                                
                    this.redirecionarParaListagemDePacientes(parametrosParaRedirecionamento) 
                }                  
            },
            async cancelarReserva(evento)
            {
                const params = []
                params.push(`id=${evento.id}`)
                params.push(`acao=cancelar`)
                try
                {
                    const url = `/api/agenda/agendastatus?${params.join('&')}`
                    const response = await this.$http.get(url)
                } catch (error)
                {
                    console.error(error)
                    throw new Error(this.$t('INSPECAO.ERROAOCANCELARRESERVA'))
                }
            },
            async reservaDuradoura(evento)
            {
                const params = [];
                params.push(`id=${evento.id}`);
                params.push(`executanteId=${evento.executanteId}`);
                try
                {
                    const url = `/api/agenda/FinalizarParaReservaDuradoura?${params.join('&')}`
                    const resposta = await this.$http.post(url)
                    return true
                } catch(error)
                {
                    console.error(error)
                    throw error
                }
            },
            async reservaTemporaria(evento, examesIds)
            {
                const params = [];
                if (examesIds != null) params.push(`examesIds=${examesIds.join(',')}`)
                if (evento.dataHoraFim) params.push(`dataHoraFim=${moment(evento.dataHoraFim).format('YYYY-MM-DDTHH:mm:ss')}`);
                evento.tipoAgendamentoId == null 
                    ? params.push(`tipoAgendamentoId=${evento.tipoAgendamento.id}`)
                    : params.push(`tipoAgendamentoId=${evento.tipoAgendamentoId}`) 
                params.push(`convenioId=${this.convenioIdAgendar}`)
                params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);
                if (evento.funcionarioId == null) evento.funcionarioId = 0;

                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`)
                else params.push('unidadeid=');

                if(this.reagendamento != null && this.reagendamento.reagendar == true){
                    params.push(`reagendar=${this.reagendamento.reagendar}`);   
                } 
                else {
                    params.push(`reagendar=${false}`);
                }
                
                params.push(`observacao=${'RESERVADO_TEMPORARIAMENTE'}`);
                // TODO: O mais apropriado é tratar esse status de reservado no backend e retirar da observação
                
                try
                {
                    const url = `/api/agenda/agendaincluir?${params.join('&')}`
                    const resposta = await this.$http.post(url)
                    
                    if (resposta.body.id) return resposta.body.id
                    throw new Error(resposta.body.mensagem)
                } catch(error)
                {
                    console.error(error)
                    throw error
                }
            },
            async adicionarAgendamento(evento, equip, examesIds, bloqueio) {
                const params = [];

                if(examesIds != null){
                    params.push(`examesIds=${examesIds.join(',')}`)
                }

                if (evento.id) params.push(`id=${evento.id}`);

                evento.tipoAgendamentoId == null 
                    ? params.push(`tipoAgendamentoId=${evento.tipoAgendamento.id}`)
                    : params.push(`tipoAgendamentoId=${evento.tipoAgendamentoId}`) 

                    params.push(`encaixe=${evento.encaixe}`);
                params.push(`convenioId=${this.convenioIdAgendar}`)
                if(this.convenioAnterior != null)
                    params.push(`convenioAnteriorId=${this.convenioAnterior}`)
                if(evento.data != null) {
                    params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);
                }
                if(evento.dataHora != null) {
                    params.push(`datahora=${moment(evento.dataHora).format('YYYY-MM-DDTHH:mm:ss')}`);
                }

                if(evento.funcionarioId == null) evento.funcionarioId = 0;
                
                if(bloqueio){
                    params.push(`funcionarioId=${evento.funcionarioId}`);
                    params.push(`motivo=${evento.motivo}`);
                } else {
                    params.push(`funcionarioId=${evento.executanteId}`);
                }

                params.push(`dataHoraFim=${moment(evento.dataHoraFim).format('YYYY-MM-DDTHH:mm:ss')}`);
                
                if (this.$route.query.unidadeId != null)
                    params.push(`unidadeId=${this.$route.query.unidadeId}`)
                else
                    params.push('unidadeid=');

                if (this.id && evento.observacao!='Bloqueio' ) params.push(`guiaId=${this.id}`);
                if (this.reagendamento && this.reagendamento.id ) {
                    params.push(`reagendamentoId=${this.reagendamento.id}`);
                    params.push(`pacienteId=${this.reagendamento.pacienteId}`);

                    if(this.reagendamento.reagendar == true){
                        params.push(`reagendar=${this.reagendamento.reagendar}`);   
                    } 
                    else {
                        params.push(`reagendar=${false}`);
                    }
                    if(this.observacoesInspecao)
                        params.push(`observacao=${this.observacoesInspecao}`);  
                    
                    params.push(`reservaTemporariaId=${evento.id}`)
                }else{
                    // TODO: O mais apropriado é tratar esse status de reservado no backend e retirar da observação
                    params.push(`observacao=${evento.observacao ? evento.observacao : 'Reservado'}`);
                    let pacienteId = evento.pacienteId ? evento.pacienteId : this.paciente.id;

                    // TODO: O mais apropriado é tratar esse status de reservado no backend e retirar da observação
                    if (evento.observacao == 'Bloqueio' || pacienteId == null) pacienteId = 0
                    params.push(`pacienteId=${pacienteId}`)                    
                }

                try {
                    console.log(params, 'params')
                    let url = `/api/agenda/agendaincluir?${params.join('&')}`;
                    
                    if (evento.id && evento.id>0 && evento.pacienteId) {
                        url = `/api/agenda/agendaEditar?${params.join('&')}`;
                    }

                    if (evento.id && evento.finalizarReserva && (this.paciente?.id || this.paciente?.nome))
                    {
                        url = `/api/agenda/FinalizarAgendamentoApartirDeUmaReservaTemporaria?${params.join('&')}`
                    }

                    if (evento.id && evento.finalizarReserva && this.reagendamento?.id)
                        url = `/api/agenda/FinalizarReagendamentoApartirDeUmaReservaTemporaria?${params.join('&')}`

                    if(this.examesBloqueados == null)
                        this.isLoading = true;

                    const res = await this.$http.post(url)
                    .then(res => res.data)
                    .then(data => {
                        if (data.mensagem != null && data.mensagem != "") {
                           this.isLoading = false;
                           this.$buefy.toast.open({
                            duration: 5000,
                            message: data.mensagem,
                            type: 'is-danger',
                            queue: false
                            });

                           this.retornoSucesso = false;
                        }else if(equip) {                            
                            evento.id = data.id;                            
                        }
                        this.dataDiaFiltro = data.dataDiaFiltro;
                    });   
                                       
                    this.agendas.forEach(a => {
                        this.carregarDataDia(a);    
                    });  

                    if(this.retornoSucesso && this.reagendamento != null){
                        this.permiteSalvar = false;
                        this.$router.push({ name: 'agenda-relacao' }).then(() => {
                            this.$nextTick(() => {
                                this.reagendamento = null;
                            });
                        });
                    }
                    return this.retornoSucesso;
                                                                
                } catch (e) {                    
                    console.error(e);
                    this.isLoading = false;
                }
                
                return true;

            },                      
            async carregaPaciente() {
                if(this.selected){
                    await this.$http.get('/api/manutencao/paciente?id=' + this.selected.id)
                              .then(res => {
                                this.paciente = res.data;

                                if(this.paciente.dataDeNascimento){
                                    this.paciente.dataDeNascimento = new Date(this.paciente.dataDeNascimento)
                                }
                                
                                this.buscaPaciente = null
                              })
                }
            },
            procurarPacienteAutocomplete: debounce(function (name) {
                if (!name.length) {
                    this.listaPacienteAutocomplete = []
                    return
                }
                this.isFetching = true

                const params = [
                    `procurar=${name}`,
                    `page=1`                                      
                ].join('&')

                this.$http.get(`/api/manutencao/pacientes?${params}`)
                    .then(({ data }) => {
                        this.listaPacienteAutocomplete = data.lista
                    })
                    .catch((error) => {
                        this.listaPacienteAutocomplete = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),
            async salvarPaciente(){
                this.isLoading = true;
                await this.$http.post('/api/manutencao/paciente', this.paciente)
                        .then((res) => {
                            if(res.data.mensagem != null){
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: res.data.mensagem,
                                    type: 'is-info',
                                    queue: false
                                })
                            }
                            this.pacienteFoiSalvo = true;
                            this.paciente.id = res.data.id;
                            this.isLoading = false;
                        })
                        .catch(err => {
                            this.isLoading = false;
                            console.error(err);
                            if (err?.body?.errors)
                            {
                                Object.keys(err.body.errors).forEach(x => {
                                this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                                })
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: this.$t('PACIENTECADASTRO.ERROPACIENTE') + err.body.title,
                                    type: 'is-danger',
                                    queue: false
                                })
                            } else 
                            {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: this.$t('PACIENTECADASTRO.ERROPACIENTE'),
                                    type: 'is-danger',
                                    queue: false
                                })
                            }
                        })
            },
            async validarCPF() {
                const res = await this.$http.get(`/api/manutencao/CheckCPF?cpf=${this.paciente.cpf}&id=${this.paciente.id}`);
                if (res.data.error){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('PACIENTECADASTRO.MSGERROCPF'),
                        type: 'is-danger',
                        queue: false
                    });
                    this.$refs.cpf.$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    return false;
                }
                if (res.data > 0) {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('PACIENTECADASTRO.CPFUSO'),
                        type: 'is-danger',
                        queue: false
                    });
                    return false;
                }
                return true;
            },
            verificarEmail() {
                this.$http.get(`/api/manutencao/VerificarUsuarioEmail?email=${this.paciente.email}`)
                    .then(res => {
                        if (res.data?.existe && !(res.data.tipo == 'paciente' && res.data.id == this.paciente.id)) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('PACIENTECADASTRO.EMAILMSG') + res.data.email + this.$t('PACIENTECADASTRO.EMUSO') + res.data.tipo + ' código ' + res.data.id,
                                type: 'is-danger',
                                queue: false
                            })
                            this.paciente.email = null
                        }
                    });
            },
            abrirModalSms() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalEnviarSms,
                    props: {
                        numero: this.paciente.celular,
                        id: this.paciente.id
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            setNomePaciente(){
                if (this.buscaPaciente) {
                    this.paciente.nome = this.buscaPaciente;
                }
                this.buscaPaciente = null;
            },
            carregaPacientePorCPF(){
                if(this.paciente.cpf){
                    this.$http.get('/api/manutencao/paciente?cpf=' + this.paciente.cpf)
                        .then(res => res.data)
                        .then(data => {
                            if (data){
                                this.$buefy.dialog.confirm({
                                    title: this.$t('INSPECAO.PACIENTEENCONTRADO'),
                                    message: this.$t('INSPECAO.CONFIRMAINCLUSAO') + data.nome + '</b> ?',
                                    confirmText: this.$t('SISTEMA.CONFIRMAR'),
                                    type: 'is-info',
                                    hasIcon: true,
                                    cancelText: this.$t('SISTEMA.CANCELAR'),                                               
                                    onConfirm: () => {                                
                                        this.paciente = data;
                                    },
                                    onCancel: () => {
                                        this.paciente.cpf = null
                                    }
                                });
                            }
                        })
                }
            },
            alterouConvenio(){
                const params = []; 
                const exames = this.examesSelecionados.map(item => item.id).join(',');
                
                params.push(`convenioId=${this.convenioIdAgendar}`);
                params.push(`examesIds=${exames}`);
                if(exames != ""){
                    if(this.reagendamento != null)
                        this.reagendamento.reagendar = true;
                    
                    this.$http.get(`/api/agenda/VerificaExameBloqueado?${params.join('&')}`)
                        .then(res => {
                            this.examesBloqueados = res.data.examesBloqueados;
                            if(this.examesBloqueados != null && this.examesBloqueados.length>0){
                                const examesFormatados = '<br>' + this.examesBloqueados.map(exame => `<strong>${exame}</strong>`).join(', ');
                                this.$buefy.dialog.alert({
                                    title: this.$t('AGENDA.EXAMESBLOQUEADOS'),
                                    message: this.$t('AGENDA.EXAMESBLOQUEADOSMSG', {'0': examesFormatados}),
                                    type: 'is-info',
                                    hasIcon: true,
                                    onConfirm: () => {
                                        this.convenioIdAgendar = this.reagendamento != null ? this.reagendamento.convenioId : this.convenioAnterior == null ? "" : this.convenioAnterior;
                                        if(this.reagendamento != null && this.convenioIdAgendar == this.reagendamento.convenioId)
                                            this.tipoAgendamentoDisabled = true;
                                    }
                                });
                            }
                            else {
                                if(this.eventosSalvar<=0 && (this.convenioIdAgendar != this.convenioAnterior && this.convenioAnterior != null) && this.reagendamento != null && this.reagendamento.convenioId != this.convenioIdAgendar){
                                    this.permiteSalvar = true;
                                }
                                this.tipoAgendamentoDisabled = false;
                            }
                        })
                }
            },
            async validaCamposPaciente(){
                const iniciouCadstroPaciente = Object.values(this.paciente).some(valor => valor !== null && valor !== "");

                if (iniciouCadstroPaciente){
                    if (this.config.cpfObrigatorio && (this.paciente.cpf == 0 || this.paciente.cpf == null || this.paciente.cpf == '' || this.paciente.cpf == undefined)) {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('INSPECAO.CPFOBRG'),
                            type: 'is-danger',
                            queue: false
                        });
                        return false;
                    }

                    if (!this.paciente.dataDeNascimento) {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('INSPECAO.NASCIMENTOOBRG'),
                            type: 'is-danger',
                            queue: false
                        });
                        return false;
                    }

                    if (!this.paciente.sexo) {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('INSPECAO.SEXOOBRG'),
                            type: 'is-danger',
                            queue: false
                        });
                        return false;
                    }

                    if(this.config.emailObrigatorio){
                        if(this.paciente.email == 0 || this.paciente.email == null || this.paciente.email == '' || this.paciente.email == undefined){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('PACIENTECADASTRO.EMAILOBRG'),
                                type: 'is-danger',
                                queue: false
                            });
                            return false;
                        }
                    }

                    if(this.config.celularObrigatorio){
                        if(this.paciente.celular == 0 || this.paciente.celular == null || this.paciente.celular == '' || this.paciente.celular == undefined){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('PACIENTECADASTRO.CELOBRG'),
                                type: 'is-danger',
                                queue: false
                            });
                            return false;
                        }
                    }

                    if (this.config.cpfUnico && this.paciente.cpf != 0 && this.paciente.cpf != null && this.paciente.cpf != '' && this.paciente.cpf != undefined) {
                        if (!this.paciente.id) {
                            this.paciente.id = 0;
                        }
                        const cpfValido = await this.validarCPF();
                        if (!cpfValido) 
                            return false;
                    }
                }

                return true;
            },
        }
    }
</script>
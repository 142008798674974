import { render, staticRenderFns } from "./faturas.vue?vue&type=template&id=4c79eecc&scoped=true"
import script from "./faturas.vue?vue&type=script&lang=js"
export * from "./faturas.vue?vue&type=script&lang=js"
import style0 from "./faturas.vue?vue&type=style&index=0&id=4c79eecc&prod&lang=css"
import style1 from "./faturas.vue?vue&type=style&index=1&id=4c79eecc&prod&scoped=true&lang=css"
import style2 from "./faturas.vue?vue&type=style&index=2&id=4c79eecc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c79eecc",
  null
  
)

export default component.exports